.doctorWrapper {
  width: 100%;
  min-height: 100%;
  .locker {
    width: 100%;
    height: 100%;
    background: #e5e5e5;
  }
  .header {
    display: flex;
    justify-content: space-between;
    //height: 40px;
    .clientInfo {
      .patientAddress {
        position: relative;
        margin-left: auto;
        display: flex;
        align-items: center;
        margin-right: 20px;
      }
      .addressText {
        position: relative;
        margin-left: auto;
        display: flex;
        align-items: center;
        font-family: "SF UI Display Medium", sans-serif;
        font-size: 20px;
        margin-right: 20px;
        color: #4b9af5d5;
        &:hover {
          cursor: pointer;
          color: #4b9bf5;
        }
      }
      .arrow-down {
        width: 20px;
        height: 10px;
        margin-left: 15px;
        margin-top: 7px;
        cursor: pointer;
      }
      .subscriber {
        margin-left: 10px;
        width: 25px;
        height: 25px;
        .blueCircle {
          position: relative;
          background-color: #4b9bf5;
          width: 25px;
          height: 25px;
          border-radius: 50%;
          .checkMark {
            position: absolute;
            width: 15px;
            height: 8px;
            border-left: #e5e5e5 3px solid;
            border-bottom: #e5e5e5 3px solid;
            top: 40%;
            left: 51%;
            transform: translate(-50%, -50%) rotate(-45deg);
          }
        }
      }
      .arrow-left {
        margin-top: 10px;
      }
      .patientImage {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 40px;
        height: 40px;
        margin: 0 0 0 27px;
        border-radius: 8px;
        background-color: #f6f7fb;
        font-family: "SF UI Display Medium", sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 19px;
        color: #4b9bf5;
      }
      .doctorImage {
        width: 40px;
        height: 40px;
        margin-left: 27px;
        border-radius: 8px;
      }
    }
    .favoritePatientBtn {
      padding: 0;
      height: 41px;
      &.isFavoriteTrue {
        text-align: center;
        background: #7dd952;
        cursor: not-allowed;
        span {
          font-weight: 500;
          font-size: 12px;
          color: white;
          justify-content: center;
        }
      }
      &.isFavoriteFalse {
        &[disabled] {
          background: lightgrey;
          cursor: default;
        }
        background: #4b9bf5;
        span {
          font-weight: 500;
          font-size: 12px;
          color: white;
          justify-content: center;
        }
      }
    }

    button {
      width: 200px;
      span {
        display: flex;
        align-items: center;
        width: 200px;
        font-family: "SF UI Display Medium", sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 17px;
        color: #272d2d;
        img {
          width: 30px;
          height: 30px;
          // margin-top: -6px;
          margin-right: 15px;
        }
      }
    }
  }
}
.clientInfo {
  display: flex;
  width: 100%;
  min-height: 41px;
  .previousBox {
    &:hover {
      cursor: pointer;
    }
  }
  .doctorDetails {
  }
}

.doctorName,
.doctorPhone,
.doctorMe {
  font-family: "SF UI Display Medium", sans-serif;
  font-style: normal;
  color: #272d2d;
  margin-left: 20px;
}
.doctorName {
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
}
.doctorPhone {
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
}
.doctorMe {
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  color: #4b9bf5;
}

.doctorContentContainer {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 24px;
  margin-bottom: 20px;
  height: 100%;
}
.consultStatusRecord {
  margin-left: 20px;
  height: 25px;
  opacity: 0.8;
  margin: auto 0;
  margin-left: 20px;
  &.new {
    padding: 5px 25px;
    background: #fce022;
    border-radius: 15px;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: #272d2d;
  }
  &.in_process {
    @extend .new;
    background: #52d9d1;
    color: #ffffff;
  }
  &.closed {
    @extend .new;
    background: #7dd952;
    color: #ffffff;
  }
  &.cancelled {
    @extend .new;
    background: #fd3845;
    color: #ffffff;
  }
  &.filled {
    @extend .new;
    background: #4b9bf5;
    color: #ffffff;
  }
}
